import { React, useState, useEffect } from "react";
import "./Dashboard.css";
import { useUser } from "../../utils/utils";
import { callApi } from "../api/Api";

function Dashboard() {
  const { user, setUser } = useUser();
  const [userList, setUserList] = useState([]);
  const [shopList, setShopList] = useState();
  const [serviceList, setServiceList] = useState();
  const [propertyList, setPropertyList] = useState();
  const [filteredShopList, setFilteredShopList] = useState([]);
  const [filteredServiceList, setFilteredServiceList] = useState([]);
  const [filteredPropertyList, setFilteredPropertyList] = useState([]);

  // console.log("userList =>", userList.total_rows)

  useEffect(() => {
    UserDataFetch();
  }, []);

  useEffect(() => {
    ShopList();
  }, []);

  useEffect(() => {
    ServiceList();
  }, []);

  useEffect(() => {
    PropertyList();
  }, []);

  // useEffects for filter

  useEffect(() => {
    FilteredShopList();
  }, []);

  useEffect(() => {
    FilteredServiceList();
  }, []);

  useEffect(() => {
    FilteredPropertyList();
  }, []);

  async function UserDataFetch() {
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };

    try {
      const userData = await callApi(
        `${baseUrl}/modules/users/get-users`,
        "POST",
        headers
      );
      setUserList(userData);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  }

  async function ShopList() {
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    const payload = {
      area_id: user.area_id,
      page_size: 1,
    };

    // setIsLoading(true);
    try {
      const shopListData = await callApi(
        `${baseUrl}/modules/shops/get-Shops`,
        "POST",
        headers,
        payload
      );

      setShopList(shopListData);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
    // finally {
    //   setIsLoading(false);
    // }
  }

  async function ServiceList() {
    const baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };

    const payload = {
      area_id: user?.area_id,
    };
    try {
      const serviceListData = await callApi(
        `${baseUrl}/modules/services/get-services`,
        "POST",
        headers,
        payload
      );
      setServiceList(serviceListData);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  }

  async function PropertyList() {
    const baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    const payload = {
      area_id: user.area_id,
    };
    // setIsLoading(true);
    try {
      const propertyListData = await callApi(
        `${baseUrl}/modules/properties/get-properties`,
        "POST",
        headers,
        payload
      );
      setPropertyList(propertyListData);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
    // finally {
    //   //turn on loader
    //   setIsLoading(false);
    // }
  }

  async function FilteredShopList() {
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    const payload = {
      area_id: user.area_id,
      filters: {
        conditions: [
          {
            field: "ed.status",
            operator: "like",
            value: "STS_LIVE",
            dataType: "string",
          },
        ],
      },
    };
    // setIsLoading(true);
    try {
      const filteredShopList = await callApi(
        `${baseUrl}/modules/shops/get-Shops`,
        "POST",
        headers,
        payload
      );

      setFilteredShopList(filteredShopList.records);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
    // finally {
    //   setIsLoading(false);
    // }
  }

  async function FilteredServiceList() {
    const baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };

    const payload = {
      area_id: user?.area_id,
      filters: {
        conditions: [
          {
            field: "ed.status",
            operator: "like",
            value: "STS_LIVE",
            dataType: "string",
          },
        ],
      },
    };
    try {
      const filterdServiceList = await callApi(
        `${baseUrl}/modules/services/get-services`,
        "POST",
        headers,
        payload
      );
      setFilteredServiceList(filterdServiceList.records);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  }

  async function FilteredPropertyList() {
    const baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    const payload = {
      area_id: user.area_id,

      filters: {
        conditions: [
          {
            field: "ed.status",
            operator: "like",
            value: "STS_LIVE",
            dataType: "string",
          },
        ],
      },
    };
    // setIsLoading(true);
    try {
      const filterdPropertyList = await callApi(
        `${baseUrl}/modules/properties/get-properties`,
        "POST",
        headers,
        payload
      );
      setFilteredPropertyList(filterdPropertyList.records);
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
    // finally {
    //   //turn on loader
    //   setIsLoading(false);
    // }
  }

  return (
    <div>
      <div className="page-content">
        <div className="row">
          <div className="col-6 col-md-6 col-lg-4 ">
            <div className="card">
              <div className="card-body">
                <div className="row d-flex align-items-center justify-content-center">
                  <div className="col-md-5 col-lg-5 col-xl-4 col-sm-12 d-flex justify-content-center">
                    <div className="icon-card text-center p-3">
                      <i className="bi bi-person-circle display-5 product-icon"></i>
                    </div>
                  </div>
                  <div className="col-md-7 col-lg-7 col-xl-8 col-sm-12 card-content">
                    <h4 className="text-muted font-weight-normal d-flex align-items-center">
                      Users
                    </h4>
                    <h6 className="font-extrabold mb-0">
                      {userList?.total_rows}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-6 col-lg-4 ">
            <div className="card">
              <div className="card-body">
                <div className="row d-flex align-items-center justify-content-center">
                  <div className="col-md-5 col-lg-5 col-xl-4 col-sm-12 d-flex justify-content-center">
                    <div className="icon-card text-center p-3">
                      <i className="bi bi-bag-fill display-5 product-icon"></i>
                    </div>
                  </div>
                  <div className="col-md-7 col-lg-7 col-xl-8 col-sm-12 card-content">
                    <h4 className="text-muted font-weight-normal d-flex align-items-center">
                      Shops
                    </h4>
                    <h6 className="font-extrabold mb-0">
                      {shopList?.total_rows}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-6 col-lg-4 ">
            <div className="card">
              <div className="card-body">
                <div className="row d-flex align-items-center justify-content-center">
                  <div className="col-md-5 col-lg-5 col-xl-4 d-flex justify-content-center">
                    <div className="icon-card text-center p-3">
                      <i className="bi bi-tools display-5  product-icon"></i>
                    </div>
                  </div>
                  <div className="col-md-7 col-lg-7 col-xl-8 card-content">
                    <h4 className="text-muted font-weight-normal">Services</h4>

                    <h6 className="font-extrabold mb-0">
                      {serviceList?.total_rows}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-6 col-lg-4 ">
            <div className="card">
              <div className="card-body">
                <div className="row d-flex align-items-center justify-content-center">
                  <div className="col-md-5 col-lg-5 col-xl-4 d-flex justify-content-center">
                    <div className="icon-card text-center p-3">
                      <i className="bi bi-houses-fill display-5  product-icon"></i>
                    </div>
                  </div>
                  <div className="col-md-7 col-lg-7 col-xl-8 card-content">
                    <h4 className="text-muted font-weight-normal">
                      Properties
                    </h4>
                    <h6 className="font-extrabold mb-0">
                      {propertyList?.total_rows}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          </div>

           <div className="row">
            {/* shops List */}
            <div className="col-12 col-sm-12 col-md-6">
              <h4>Active Shops</h4>
              <div class="page-heading">
                <div class="page-title">
                  <section className="section">
                    <div className="card">
                      <div className="card-body">
                        <div className="dataTable-container">
                          <table className="table table-striped" id="table1">
                            <thead>
                              <tr>
                                <th>name</th>
                                <th>Landmark</th>
                              </tr>
                            </thead>

                            <tbody>
                              {filteredShopList &&
                                filteredShopList.length > 0 &&
                                filteredShopList
                                  .slice(0, 10)
                                  .map((data, index) => (
                                    <tr key={index}>
                                      <td>{data.name}</td>
                                      <td>{data.line_1}</td>
                                    </tr>
                                  ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>

            {/* services List */}
            <div className="col-12 col-sm-12 col-md-6">
              <h4>Active Services</h4>
              <div class="page-heading">
                <div class="page-title">
                  <section className="section">
                    <div className="card">
                      <div className="card-body">
                        <div className="dataTable-container">
                          <table className="table table-striped" id="table1">
                            <thead>
                              <tr>
                                <th>name</th>
                                <th>Landmark</th>
                              </tr>
                            </thead>

                            <tbody>
                              {filteredServiceList &&
                                filteredServiceList.length > 0 &&
                                filteredServiceList
                                  .slice(0, 10)
                                  .map((data, index) => (
                                    <tr key={index}>
                                      <td>{data.name}</td>
                                      <td>{data.line_1}</td>
                                    </tr>
                                  ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>

            {/* properties List */}
            <div className="col-12 col-sm-12 col-md-6">
              <h4>Active Properties</h4>
              <div class="page-heading">
                <div class="page-title">
                  <section className="section">
                    <div className="card">
                      <div className="card-body">
                        <div className="dataTable-container">
                          <table className="table table-striped" id="table1">
                            <thead>
                              <tr>
                                <th>Email</th>
                                <th>Landmark</th>
                              </tr>
                            </thead>

                            <tbody>
                              {filteredPropertyList &&
                                filteredPropertyList.length > 0 &&
                                filteredPropertyList
                                  .slice(0, 10)
                                  .map((data, index) => (
                                    <tr key={index}>
                                      <td>{data.email}</td>
                                      <td>{data.line_1}</td>
                                    </tr>
                                  ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
