


export const callApi = async (url, method,headers={}, payload={},navigate) => {

  const option ={
    method:method,
    headers:headers,
    body:JSON.stringify(payload)
  }
  console.log("CheckApi", url)
  try {
    const response = await fetch(url, option)

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`)
    }
    const data = await response.json()
      // console.log("Success:", data)
    return data
  } catch (error) {
    console.error("Error:", error)
  }
}
