import "./errorMessages.css"

export const errorMessages = {
"name_reqd": "Title is required",
    "user_not_found": "User not found",
    "first_name_reqd": "First Name is required",
    "last_name_reqd": "Last Name is required",
    "email_reqd":"Email is required",
    "email_invalid":"Please enter the valid email",
    "email_already_exists":"Email already exists",
    "otp_code_reqd":'Please enter the Otp',
    "email_otp_code_reqd":'Please enter the Otp',
    "email_otp_code_reqd":"Please enter the Otp",
    "otp_invalid":"Please enter the Valid Otp",
    entity_type_reqd: "Entity type is required",
    latitude_reqd: "Latitude is required",
    longitude_reqd: "Longitude is required",
    line_1_reqd: "Address is required",
    area_reqd: "Area is required",
    "otp_code_reqd":"OTP is required",
    "Invalid_otp_code":"Please enter the Valid Otp",
    city_reqd: "City is required",
    state_reqd: "State is required",
    zip_reqd: "Pincode is required",
    country_reqd: "Country is required",
    posted_by_reqd:"Posted by is requied",
    land_area_reqd:"Land Area is required",
    built_up_area_reqd:"Built Up Area is required",
    direction_facing_reqd:"Direction is required",
    price_reqd:"Price is required",
    number_of_bedrooms_reqd:"Number of bedroom is required",
    phone_reqd:"Phone Number is required",
    "name_of_community_reqd":"Name of the Community is required",
    "carpet_area_reqd":"Carpet Area is required",
    property_type_doesnot_exists:"Property does not exists",
    category_id_doesnot_exists:"Category does not exists",
    area_id_doesnot_exists:'Area does not exists',
    address_id_doesnot_exists:'Address does not exists',
    posted_by_doesnot_exists:"Posted does not exists",
    featuredImage_doesnot_exists:"Featured Images does not exists",
    approving_agency_doesnot_exists:"Approving Agency does not exists",
    furnish_level_reqd:"Furnish Level is required",
    maintanance_charges_reqd:"Maintanance Charges is required",
    approving_agency_reqd:"Approving Agency is required",
    total_floors_reqd:"Total Floors is required",
    age_reqd:"Property Age is required",
    pet_allowed_reqd:"Number of Pets is required",
    number_of_bathrooms_reqd:"Number of Bathrooms is required",
    floor_number_reqd:"Number of Floor is required",
    car_parking_reqd:"Car Parking is required",
    suitable_for_business_reqd:"Suitable For Business is required",
    rent_reqd:'Rent is required',
    available_from_reqd:"Available is required",
    gender_reqd:"Gender is required",
    rent_per_person_reqd:"Rent per person is required",
    food_available_reqd:"Food Available is required",
    person_per_room_reqd:"Person per room is required",
    geo_location_reqd:"Location is mandatory",
    role_code_reqd:"Role code is mandatory"
}
 