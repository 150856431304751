import React, { useState } from "react";
import "./Header.css";
import logo from "../../assets/logo/logo.png";
import Sidebar from "../sidebar/Sidebar";
import SelectedAreaDropdown from "../selector/SelectedAreaDropdown";
import Logout from "../auth/logout/Logout";

const HypsoHeader = () => {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };

  const closeSidebar = () => {
    setHamburgerOpen(false);
  };

  return (
    <>
      <header className="mb-3">
        <a
          href="#"
          className="burger-btn d-block d-xl-none"
          onClick={toggleHamburger}
        >
          <i className="bi bi-justify fs-3"></i>
        </a>
      </header>
      {hamburgerOpen && <div className="overlay" onClick={closeSidebar}></div>}
      <div className={`sidebar ${hamburgerOpen ? "" : "hidden"}`}>
        <Sidebar />
      </div>

      <div className="row d-flex justify-content-end">
        <div className="col-md-3 col-10 mb-3 ">
          <SelectedAreaDropdown />
        </div>
        <div className="col-md-1 col-2 mb-3">
          <Logout />
        </div>
      </div>
    </>
  );
};

export default HypsoHeader;
