
import React, { useState } from "react"
import SidebarDropdown from "./SidebarDropdown"

const MenuItem = ({ menuItem, index }) => {
  const [dropdown, setDropdown] = useState(false)

  const toggleDropdown = () => {
    setDropdown(!dropdown)
  }

  const hasSubMenu = menuItem.subMenu && menuItem.subMenu.length > 0

  return (
    <li
      className={`sidebar-item ${hasSubMenu ? "has-sub" : ""}`}
      onClick={toggleDropdown} key={index}
    >
      <a href={menuItem.url} className="sidebar-link">
        {menuItem.title}
      </a>
      {dropdown && hasSubMenu && <SidebarDropdown submenus={menuItem.subMenu} />}
    </li>
  )
}

export default MenuItem
