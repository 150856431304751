import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./App.css";
import "./assets/app.css";
import AddShop from "./components/shop/AddShop/AddShop";
import EditShop from "./components/shop/EditShop/EditShop";
import Login from "./components/auth/login/Login";
import ServiceList from "./components/Service/serviceList/ServiceListings";
import AddService from "./components/Service/AddService/AddService";
import OTPCapture from "./components/auth/otpCapture/OTPCapture";
import { UserContext } from "./contexts/UserContext";
import EditService from "./components/Service/EditService/EditService";
import Logout from "./components/auth/logout/Logout";
import PageNotFound from "./components/pageNotFound/PageNotFound";
import AddBanner from "./components/banner/addBanner/AddBanner";
import BannerList from "./components/banner/bannerList/BannerList";
import EditBanner from "./components/banner/bannerEdit/EditBanner";
import PropertyList from "./components/realEstate/propertyList/PropertyListings";
import PropertyForm from "./components/realEstate/propertyForm/PropertyForm";
import ShopListings from "./components/shop/shopList/ShopListings";
import Dashboard from "./components/dashboard/Dashboard";
import UserList from "./components/user/userList/UserList";
import EditUser from "./components/user/editUser/EditUser";
import AddUser from "./components/user/addUser/AddUser";
import Layout from "./components/layout/Layout";

function App() {
  const [user, setUser] = useState();
  

  return (
    <BrowserRouter>
      <div id="main">
        <UserContext.Provider value={{ user: user, setUser: setUser }}>
          <Layout>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="*" element={<PageNotFound />} /> 
              <Route path="/logout" element={<Logout />} />
              <Route path="/otp-capture/:email" element={<OTPCapture />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/shops" element={<ShopListings />} />
              <Route path="/shop-new" element={<AddShop />} />
              <Route path="/shop-edit/:id" element={<EditShop />} />
              <Route path="/services" element={<ServiceList />} />
              <Route path="/service-new" element={<AddService />} />
              <Route path="/service-edit/:id" element={<EditService />} />
              <Route path="/properties" element={<PropertyList />} />
              <Route path="/property-new" element={<PropertyForm />} />
              <Route path="/property-edit/:id" element={<PropertyForm />} />
              <Route path="/banner-new" element={<AddBanner />} />
              <Route path="/banner-list" element={<BannerList />} />
              <Route path="/banner-edit" element={<EditBanner />} />
              <Route path="/users" element={<UserList />} />
              <Route path="/user-edit/:id" element={<EditUser />} />
              <Route path="/user-new" element={<AddUser />} />
            </Routes>
          </Layout>
        </UserContext.Provider>
      </div>
    </BrowserRouter>
  );
}

export default App;
