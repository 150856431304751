import React, { useState, useEffect } from "react";
import "./MultiImageSelector.css";
import classNames from "classnames";
import arrayUtils from "../../utils/arrayUtils";
import imageUpload from "../../assets/Shopimage/uploadImage.jpg";
import { callApi } from "../api/Api";
import { useUser } from "../../utils/utils";
import loading_gif from "../../assets/gif/loading_gif.gif";
import Pagination from "../pagination/Pagination";

function MultiImageSelector({
  selectedImages = [],
  setSelectedImages,
  label,
  className,
}) {
  const [galleryImages, setGalleryImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(null);
  const [totalPage, setTotalPage] = useState(null);
  const { user } = useUser();

  useEffect(() => {
    getImagesByUser();
  }, [currentPage]);

  async function getImagesByUser() {
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    var payload = {
      area_id: 1,
      user_id: user.user_id,
      current_page: currentPage,
      page_size: 9,
    };

    try {
      const getUserImages = await callApi(
        `${baseUrl}/modules/images/get-images-by-user`,
        "POST",
        headers,
        payload
      );
      console.log("getUserImages", getUserImages);

      setGalleryImages(getUserImages.records || "");
      const pageTotal = getUserImages.total_pages;
      setCurrentPage(getUserImages.current_page);
      setTotalPage(Array.from({ length: pageTotal }, (_, i) => i + 1));
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  }

  const onToggleImage = (toggledImage) => {
    var current_toggle_state = selectedImages.some(
      (selectedImage) => toggledImage.image_id == selectedImage.image_id
    );
    if (current_toggle_state == true) {
      //remove toggledImage from selected images
      var updatedSelectedImages = arrayUtils.removeObjectFromArray(
        selectedImages,
        toggledImage,
        "image_id"
      );
      setSelectedImages(updatedSelectedImages);
    } else {
      //add toggledImage to selected images
      var updatedSelectedImages = [...selectedImages];
      updatedSelectedImages.push(toggledImage);
      setSelectedImages(updatedSelectedImages);
    }
  };

  const handleImageSelect = async (event) => {
    const imageToUpload = event.target.files[0];
    //upload image using api and receive image object
    await uploadImage(imageToUpload);
  };

  const uploadImage = async (imageToUpload) => {
    //turn on loader
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", imageToUpload);
      formData.append('area_id', user?.area_id);

      const headers = {
        token: user?.token,
      };

      var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

      const response = await fetch(`${baseUrl}/modules/images/upload`, {
        method: "POST",
        headers: headers,
        body: formData,
      });
      if (response.ok) {
        const uploadedImage = await response.json(); // Get response as text
        console.log("Upload Successful:" + JSON.stringify(uploadedImage));
        setGalleryImages((prevGalleryImages) => [
          uploadedImage,
          ...prevGalleryImages,
        ]);
        console.log("galleryImages =>", galleryImages);
      } else {
        console.error("Upload Failed:", response.statusText);
        // Handle error
      }
    } catch (error) {
      console.error("Upload Failed:", error);
      // Handle error
    } finally {
      //turn on loader
      setIsLoading(false);
    }
  };

  const onRemoveImage = (toRemoveImage) => {
    var current_toggle_state = selectedImages.some(
      (selectedImage) => toRemoveImage.image_id == selectedImage.image_id
    );
    if (current_toggle_state == true) {
      //remove toggledImage from selected images
      var updatedSelectedImages = arrayUtils.removeObjectFromArray(
        selectedImages,
        toRemoveImage,
        "image_id"
      );
      setSelectedImages(updatedSelectedImages);
    }
  };

  return (
    <div>
      {/* button to open image gallery */}
      <div className={className ? className : "d-none"}>
        <label htmlFor="first-name-horizontal" className="form-label">
          {label}
        </label>
      </div>
      <button
        type="button"
        className="btn btn-outline-primary block"
        data-bs-toggle="modal"
        data-bs-target="#exampleModalCenter"
      >
        Gallery images
      </button>
      <div className="selectedImagesWrapper">
        {/* selected images */}
        {selectedImages.length > 0
          ? selectedImages?.map((selectedImage, index) => (
              <div className="selecedImage_group" key={index}>
                <img className="image" src={selectedImage.path} />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={"20px"}
                  className="selectedImage_icon"
                  viewBox="0 0 512 512"
                  onClick={() => onRemoveImage(selectedImage)}
                >
                  <path
                    fill="#EA3855"
                    d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"
                  />
                </svg>
              </div>
            ))
          : null}
      </div>

      {/* Begin Image Gallery Modal*/}
      <div
        className="modal fade"
        id="exampleModalCenter"
        data-bs-backdrop="false"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered modal-dialog-centered modal-dialog-scrollable"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header bg-primary">
              <h5 className="modal-title " id="exampleModalCenterTitle">
                Image Gallery
              </h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i data-feather="x"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-5 col-md-12 col-xs-12">
                  <div className="file-upload">
                    <img src={imageUpload} className="uploadImg" alt="upload" />
                    <h3 className="fs-5"> {"Click box to upload"}</h3>
                    <input type="file" onChange={handleImageSelect} />
                  </div>
                </div>
                <div className="col-lg-7 col-md-12 col-xs-12 ">
                  <div className="galleryContainer h-100">
                    {/* Gallery images */}
                    {isLoading && (
                      <img src={loading_gif} className="loading_gif"></img>
                    )}

                    <div className="galleryImagesWrapper">
                      {galleryImages.length > 0
                        ? galleryImages?.map((galleryImage) => (
                            <div
                              className="imageGroup"
                              onClick={() => onToggleImage(galleryImage)}
                              key={galleryImage.image_id}
                            >
                              <img className="image" src={galleryImage.path} />
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className={classNames("icon", "hide", {
                                  show:selectedImages.length > 0 ? ( selectedImages.some(
                                    (selectedImage) =>
                                      galleryImage.image_id ===
                                      selectedImage.image_id
                                  )) : null
                                })}
                                width={"20px"}
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="#ffffff"
                                  d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"
                                />
                              </svg>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Pagination
                      totalPage={totalPage}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  </div>
                </div>
              </div>

              {/* Image Uploader */}

              {/* Image Search Bar */}

              {/* Pagination */}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light-secondary"
                data-bs-dismiss="modal"
              >
                <i className="bx bx-x d-block d-sm-none"></i>
                <span className="d-none d-sm-block">Close</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* End Image Galler Modal*/}
    </div>
  );
}

export default MultiImageSelector;
