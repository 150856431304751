import React, { useState, useEffect, useRef } from "react";
import logo from "../../../assets/logo/logo.png";
import { callApi } from "../../api/Api";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "../../../utils/utils";
import "../otpCapture/OTPCapture.css";
import Message from "../../errors/Message";

function OTPCapture() {
  const [userForm, setUserForm] = useState({});
  const { user, setUser } = useUser();
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const inputRefs = useRef([]);
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();
  const param = useParams();
  const email = param.email;

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleChangeOtp = (index, e) => {
    const value = e.target.value;
    if (isNaN(value)) return;
    const newOtp = [...otp];
    newOtp[index] = value.substring(value.length - 1);
    setOtp(newOtp);
    if (value && index < 5 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1);
    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf("")].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (
      e.key === "Backspace" &&
      !otp[index] &&
      index > 0 &&
      inputRefs.current[index - 1]
    ) {
      inputRefs.current[index - 1].focus();
    }
   
  };

  const handleChange = (e) => {
    setUserForm({ ...userForm, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const joinedOtp = otp.join("");

    const baseUrl = process.env.REACT_APP_DEV_BASE_URL;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const payload = {
      email_otp_code: joinedOtp,
      email,
    };

    try {
      const response = await callApi(
        `${baseUrl}/modules/users/verify-otp`,
        "POST",
        headers,
        payload
      );
      if (response.success && response.token) {
        const userLogin = { ...user, user_id: response.user_id, token: response.token, area_id: response.last_used_area }
        setUser(userLogin);
        localStorage.setItem(
          "user",
          JSON.stringify(userLogin)
        );
        navigate("/dashboard");
      } else {
        setErrors(response.errors);
      }
      console.log("response.errors", response.errors);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="row">
      <div className="col-12 col-md-5 mb-5 mt-5 login_col">
        <div id="auth-left">
          <div className="auth-logo">
            <a href="/">
              <img src={logo} alt="Logo" className="hypso_logo" />
            </a>
          </div>
          <h2 className="auth-title mt-3">OTP verification</h2>
          {/* <form onSubmit={handleSubmit}> */}
          <form action="#" className="form form-horizontal" onSubmit={handleSubmit}>
            <div className="form-body">
              <div className="form-group position-relative has-icon-left mb-3">
                <input
                  type="text"
                  name="email"
                  className="form-control form-control-xl"
                  placeholder="Email"
                  onChange={handleChange}
                  value={email}
                />
                <div>
                  <Message type={"error"} errors={errors} fieldName={"email"} />
                  <Message type={"error"} errors={errors} fieldName={"user"} />
                </div>

                <div className="form-control-icon">
                  <i className="bi bi-person"></i>
                </div>
              </div>

              <div className="form-group position-relative  otp-container">
                {otp.map((value, index) => (
                  <input
                    key={index}
                    type="text"
                    ref={(input) => (inputRefs.current[index] = input)}
                    value={value}
                    onChange={(e) => handleChangeOtp(index, e)}
                    onClick={() => handleClick(index)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    className="form-control form-control-xl otp-input"
                  />
                ))}
              </div>
              <div className="mb-2">
                <Message type={"error"} errors={errors} fieldName={"email_otp_code"} />
              </div>             
            </div>
            <button
                className="btn btn-primary btn-block btn-lg shadow-lg mt-2"
                type="submit"
              >
                Log in
              </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default OTPCapture;
