import React from "react";
import { useState, useEffect } from "react";
import { useUser } from "../../utils/utils";
import { callApi } from "../api/Api";
import loading_gif from "../../assets/gif/loading_gif.gif";

function AreaModal() {
  const { user, setUser } = useUser();
  const [areaDetails, setAreaDetails] = useState([]);
  const [userApiInfo, setUserApiInfo] = useState();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedArea, setSelectedArea] = useState();

  // useEffect for modal show
  useEffect(() => {
    if (!user.area_id) {
      setShow(true);
    }
  }, [user]);

  // useEffect for get Area 
  useEffect(() => {
    getArea();
  }, []);

  // useEffect for Update User AreaId
  useEffect(() => {
    updateUserAreaId();
  }, [user?.area_id]);

  var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

  async function getArea() {
    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    setIsLoading(true);
    try {
      const areaData = await callApi(
        `${baseUrl}/modules/areas/get-areas`,
        "POST",
        headers
      );
      setAreaDetails(areaData.records);
    } catch (error) {
      console.error("There was an error fetching the area!", error);
    } finally {
      //turn on loader
      setIsLoading(false);
    }
  }

 
  const updateUserAreaId = async () => {
    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };

    const payload = {
      user_id: user?.user_id,
      last_used_area: user?.area_id,
    };

    try {
      const areaUpdate = await callApi(
        `${baseUrl}/modules/users/update`,
        "POST",
        headers,
        payload
      );
      console.log(areaUpdate);
      setUserApiInfo(areaUpdate.record);
    } catch (error) {
      console.log("error", error);
    }
  };



  const handleAreaChange = (event) => {
    setSelectedArea(event.target.value);
  };

  const handleSubmit = () => {
    const updatedUser = { ...user, area_id: selectedArea };
    setUser(updatedUser);
    console.log("user response", user);
    localStorage.setItem("user", JSON.stringify(updatedUser));
    setShow(false);
  };

  return (
    <div>
      {userApiInfo?.last_used_area == null && !user.area_id && show && (
        <div
          className={`modal fade show`}
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          id="exampleModalCenter"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalCenterTitle">
                  Choose Your Area
                </h5>
              </div>
              <div className="modal-body">
                <div className="row">
                  {isLoading && (
                    <img src={loading_gif} className="loading_gif"></img>
                  )}
                  {areaDetails?.map((area, i) => (
                    <div className="col-md-8 m-2">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="area"
                          value={area.area_id}
                          onChange={handleAreaChange}
                        ></input>
                        <label class="form-check-label" for="flexRadioDefault1">
                          {area.name}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary ms-1"
                  onClick={handleSubmit}
                >
                  <i className="bx bx-check d-block d-sm-none"></i>
                  <span className="d-none d-sm-block">Submit</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AreaModal;
