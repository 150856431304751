import React from 'react';

const Pagination = ({ totalPage, setCurrentPage, currentPage }) => {
  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPage.length));
  };

  const handlePageChange = (event, page) => {
    event.preventDefault()
    setCurrentPage(page);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination pagination-primary ">
        <li className="page-item">
          <button className="page-link" onClick={handlePreviousPage}>
          <span aria-hidden="true"><i className="bi bi-chevron-left"></i></span>
          </button>
        </li>
        {totalPage && totalPage.map((number, index) => (
          <li
            className={`page-item ${currentPage === number ? 'active' : ''}`}
            key={index}
          >
            <button
              className="page-link"
              onClick={(e) => handlePageChange(e, number)}
            >
              {number}
            </button>
          </li>
        ))}
        <li className="page-item">
          <button className="page-link" onClick={handleNextPage}>
          <span aria-hidden="true"><i className="bi bi-chevron-right"></i></span>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
