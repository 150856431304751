import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { propertyConfig } from "../../const/PropertyConfig";
import { AllFieldsConfig } from "../../const/AllFieldsConfig";
import { useNavigate, useParams } from "react-router-dom";
import {
  useUser,
  changePropertyType,
  tochangePropertyType,
  propertyTypeLabelToshow,
} from "../../../utils/utils";
import { callApi } from "../../api/Api";
import SingleImageSelector from "../../singleImageSelector/SingleImageSelector";
import MultiImageSelector from "../../multiImageSelector/MultiImageSelector";
import AmenitySelector from "../inputComponents/AmenitySelector";
import Input from "../../realEstate/inputComponents/Input";
import Dropdown from "../../realEstate/inputComponents/Dropdown";
import Textarea from "../../realEstate/inputComponents/Textarea";
import "./PropertyForm.css";
import MobileNumberVerified from "../../mobileNumberVerified/MobileNumberVerified";
import MapComponent from "../../mapSelector/MapComponent";
import { defaultLocation } from "../../../utils/locationUtils";

function PropertyForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const [status, setStatus] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [PropertyCategoryStatus, setPropertyCategoryStatus] = useState("Buy");
  const [PropertyTypeStatus, setPropertyTypeStatus] = useState(
    "PROPERTY_TYPE_HOUSE"
  );
  const [formValues, setFormValues] = useState({});
  const [propertyInfo, setPropertyInfo] = useState({});
  const [originalFormValues, setOriginalFormValues] = useState({});
  const [allFormValues, setAllFormValues] = useState({});
  const [featuredImage, setFeaturedImage] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [rejectReason, setRejectReason] = useState({});
  const [errors, setErrors] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState();
  const [newPhoneNumber, setNewPhoneNumber] = useState(formValues?.phone);
  const [isFeatured, setIsFeatured] = useState();
  const [amenitiesList, setAmenities] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [phoneVerifyModal, setPhoneVerifyModal] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState("");
  const [showLatitude, setShowLatitude] = useState(
    defaultLocation[0]?.latitude || 0
  );
  const [showLongitude, setShowLongitude] = useState(
    defaultLocation[0]?.longitude || 0
  );

  const image_id = selectedImages?.length
    ? selectedImages.map((selectImage) => selectImage.image_id)
    : [];

  const categoryData = [
    { label: "Buy", value: "Buy", id: 263 },
    { label: "Rent", value: "Rent", id: 264 },
    { label: "Pg", value: "Pg", id: 265 },
    { label: "Room sharing", value: "Room_sharing", id: 266 },
  ];

  const [categoryId, setCategoryId] = useState(
    categoryData.find((category) => category.value === "Buy")?.id || null
  );

  // Update phoneNumber whenever formValues.phone changes
  useEffect(() => {
    setNewPhoneNumber(formValues.phone || ""); // Keep phoneNumber in sync with formValues.phone
  }, [formValues.phone]);

  useEffect(() => {
    const selectedCategory = categoryData.find(
      (category) => category.value === PropertyCategoryStatus
    );
    if (selectedCategory) {
      setCategoryId(selectedCategory.id);
    }
  }, [PropertyCategoryStatus]);

  // useEffect for set isEditMode true or false

  useEffect(() => {
    if (id) {
      setIsEditMode(true);
      fetchPropertyData(id);
    } else {
      setIsEditMode(false);
      setFormValues({});
    }
  }, []);

  //useEffect for If there is no id (indicating a new property)

  useEffect(() => {
    if (!id) {
      setFormValues({});
    }
  }, [PropertyCategoryStatus, PropertyTypeStatus, id]);

  //useEffect for if in edit mode and an id is present, fetch the property data again.

  useEffect(() => {
    if (isEditMode && id) {
      fetchPropertyData(id);
    }
  }, [id, isEditMode]);

  // Generate a key based on the current PropertyCategoryStatus and PropertyTypeStatus.

  useEffect(() => {
    const currentKey = `${PropertyCategoryStatus}_${PropertyTypeStatus}`;
    setFormValues(allFormValues[currentKey] || {});
  }, [PropertyCategoryStatus, PropertyTypeStatus, allFormValues]);

  useEffect(() => {
    if (["Pg", "Room_sharing"].includes(PropertyCategoryStatus)) {
      setPropertyTypeStatus(PropertyCategoryStatus);
    } else {
      setPropertyTypeStatus("PROPERTY_TYPE_HOUSE");
    }
  }, [PropertyCategoryStatus]);

  const fetchPropertyData = async (id) => {
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    try {
      const propertyData = await callApi(
        `${baseUrl}/modules/properties/get-property/${id}`,
        "POST",
        headers
      );
      setPropertyInfo(propertyData.record);
      setPhoneNumber(propertyData.record.phone);
      setFeaturedImage(propertyData.record.featured_image);
      setSelectedImages(propertyData.record.images);
      setOriginalFormValues(propertyData.record);
      setPropertyCategoryStatus(propertyData.record.category_name);
      setPropertyTypeStatus(
        tochangePropertyType(propertyData.record.property_type)
      );
      setStatus(propertyData.record.status);
      setIsFeatured(propertyData.record.is_featured);
      setAmenities(propertyData.record.amenities.amenities);
      setShowLatitude(parseFloat(propertyData.record.latitude));
      setShowLongitude(parseFloat(propertyData.record.longitude));
    } catch (error) {
      console.error("There was an error fetching the data!", error);
    }
  };

  useEffect(() => {
    userDataFetch();
  }, []);

  const userDataFetch = async () => {
    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    try {
      const userData = await callApi(
        `${baseUrl}/modules/users/get-user/${user?.user_id}`,
        "POST",
        headers
      );
      setUserInfo(userData.record);
    } catch (error) {
      console.log("errors", error);
    }
  };

  const getAllFieldConfigForPropertyType = () => {
    const fields =
      propertyConfig[PropertyCategoryStatus]?.[PropertyTypeStatus] ||
      propertyConfig?.[PropertyCategoryStatus];
    if (!Array.isArray(fields)) {
      return null;
    }

    return fields?.map((fieldsInARow, rowIndex) => (
      <div className="row" key={rowIndex}>
        {fieldsInARow?.map((field) => {
          if (AllFieldsConfig[field.fieldName]?.type === "textbox") {
            return (
              <div
                className="col-md-12 property-form"
                style={{ width: field.width }}
                key={field.fieldName}
              >
                <Input
                  errors={errors}
                  fieldName={field}
                  type={"text"}
                  key={field.fieldName}
                  label={field.label || AllFieldsConfig[field.fieldName]?.label}
                  name={field.fieldName}
                  onChange={handleChange}
                  value={
                    formValues?.[field.fieldName] != undefined
                      ? formValues?.[field.fieldName]
                      : propertyInfo?.[field.fieldName]
                  }
                />
              </div>
            );
          } else if (AllFieldsConfig[field.fieldName]?.type === "date") {
            return (
              <div
                className="col-md-12 property-form"
                style={{ width: field.width }}
                key={field.fieldName}
              >
                <Input
                  errors={errors}
                  fieldName={field}
                  type={"date"}
                  key={field.fieldName}
                  label={field.label || AllFieldsConfig[field.fieldName]?.label}
                  name={field.fieldName}
                  onChange={handleChange}
                  value={
                    formValues?.[field.fieldName] ||
                    propertyInfo?.[field.fieldName]
                  }
                />
              </div>
            );
          } else if (AllFieldsConfig[field.fieldName]?.type === "dropdown") {
            return (
              <div
                className="col-md-12 property-form"
                style={{ width: field.width }}
                key={field.fieldName}
              >
                <Dropdown
                  errors={errors}
                  fieldName={field}
                  key={field.fieldName}
                  label={field.label || AllFieldsConfig[field.fieldName]?.label}
                  name={field.fieldName}
                  options={AllFieldsConfig[field.fieldName]?.values}
                  onChange={handleChange}
                  value={
                    formValues?.[field.fieldName] ||
                    propertyInfo?.[field.fieldName]
                  }
                  isEditMode={isEditMode}
                />
              </div>
            );
          } else if (AllFieldsConfig[field.fieldName]?.type === "textarea") {
            return (
              <div
                className="col-md-12 property-form"
                style={{ width: field.width }}
                key={field.fieldName}
              >
                <Textarea
                  rows={4}
                  errors={errors}
                  type={"textarea"}
                  field={field}
                  key={field.fieldName}
                  label={field.label || AllFieldsConfig[field.fieldName]?.label}
                  name={field.fieldName}
                  onChange={handleChange}
                  value={
                    formValues?.[field.fieldName] != undefined
                      ? formValues?.[field.fieldName]
                      : propertyInfo?.[field.fieldName]
                  }
                />
              </div>
            );
          } else if (AllFieldsConfig[field.fieldName]?.type === "amenity") {
            return (
              <>
                <div
                  className="col-lg-12 col-sm-12 property_width"
                  style={{ width: field.width }}
                  key={field.fieldName}
                >
                  <AmenitySelector
                    field={field}
                    key={field.fieldName}
                    label={
                      field.label || AllFieldsConfig[field.fieldName]?.label
                    }
                    name={field.fieldName}
                    errors={errors}
                    options={AllFieldsConfig[field.fieldName]?.values}
                    amenities={amenitiesList}
                    setAmenities={setAmenities}
                  />
                </div>
              </>
            );
          } else if (AllFieldsConfig[field.fieldName]?.type === "map") {
            return (
              <>
                <div className="col-lg-6 col-md-12">
                  <label htmlFor="first-name-horizontal" className="form-label">
                    {field.label || AllFieldsConfig[field.fieldName]?.label}
                  </label>
                </div>
                <div
                  className="col-lg-12 col-sm-12 mb-3 p-0 property_width"
                  style={{ width: field.width }}
                  key={field.fieldName}
                >
                  <MapComponent
                    setLatitude={setLatitude}
                    setLongitude={setLongitude}
                    showLatitude={showLatitude}
                    showLongitude={showLongitude}
                    setShowLatitude={setShowLatitude}
                    setShowLongitude={setShowLongitude}
                  />
                </div>
              </>
            );
          } else if (AllFieldsConfig[field.fieldName]?.type === "singleImage") {
            return (
              <div
                className="col-lg-12 property-form"
                style={{ width: field.width }}
                key={field.fieldName}
              >
                {
                  <SingleImageSelector
                    imageFromApi={formValues?.featured_image_path}
                    key={field.fieldName}
                    label={AllFieldsConfig[field.fieldName]?.label}
                    className={AllFieldsConfig[field.fieldName]?.className}
                    name={field.fieldName}
                    imageToShow={featuredImage}
                    setFormValues={setFormValues}
                    onChangeImage={onChangeFeaturedImage}
                  />
                }
              </div>
            );
          } else if (
            AllFieldsConfig[field.fieldName]?.type === "multipleImages"
          ) {
            return (
              <div
                className="col-lg-12 property-form"
                style={{ width: field.width }}
                key={field.fieldName}
              >
                {
                  <MultiImageSelector
                    key={field.fieldName}
                    label={AllFieldsConfig[field.fieldName]?.label}
                    className={AllFieldsConfig[field.fieldName]?.className}
                    name={field.fieldName}
                    selectedImages={selectedImages}
                    setSelectedImages={setSelectedImages}
                  />
                }
              </div>
            );
          }
          return null;
        })}
      </div>
    ));
  };

  const renderButton = () => {
    if (isEditMode) {
      // Logic for edit mode
      const isPhoneVerificationRequired =
        (formValues?.phone && formValues?.phone !== userInfo?.phone) ||
        (!formValues?.phone && userInfo?.phone !== phoneNumber);

      return (
        <div className="col-sm-12 d-flex justify-content-end">
          <button
            className="btn btn-primary me-1 mb-1"
            onClick={
              isPhoneVerificationRequired
                ? handleMobileNumberVerify
                : handlePropertyUpdate
            }
          >
            Save
          </button>
        </div>
      );
    } else {
      // Logic for non-edit mode
      if (
        userInfo?.phone_verified === false ||
        formValues?.phone !== userInfo?.phone
      ) {
        return (
          <div className="col-sm-12 d-flex justify-content-end">
            <button
              className="btn btn-primary me-1 mb-1"
              onClick={handleMobileNumberVerify}
            >
              Save
            </button>
          </div>
        );
      }

      // Return Save button if phone is verified or form phone is not set
      return (
        <div className="col-sm-12 d-flex justify-content-end">
          <button
            className="btn btn-primary me-1 mb-1"
            onClick={handlePropertySave}
          >
            Save
          </button>
        </div>
      );
    }
  };

  const handleChange = (e) => {
    const currentKey = `${PropertyCategoryStatus}_${PropertyTypeStatus}`;
    const newFormValues = { ...formValues, [e.target.name]: e.target.value };
    setFormValues(newFormValues);
    setAllFormValues({ ...allFormValues, [currentKey]: newFormValues });
  };

  // handleChange for featuredImages
  const onChangeFeaturedImage = (newlySelectedImage) => {
    // setFeaturedImage(newlySelectedImage.image_id)
    setFeaturedImage(newlySelectedImage);
    setFormValues(prevFormValues => ({
      ...prevFormValues,
      featured_image: newlySelectedImage?.image_id // Also update the formValues
    }));
  };

  // handleChange for Checkbox
  const handleCheckboxChange = async (e) => {
    setIsFeatured(e.target.checked);
  };

  const handleMobileNumberVerify = async (e) => {
    e.preventDefault();

    if (!userInfo?.phone_verified || userInfo?.phone !== formValues.phone) {
      setPhoneVerifyModal(true);
    }
  };

  // Toast for Property Add
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

  const handlePropertySave = async (e) => {
    e.preventDefault();
    var headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };

    // Define which fields should be part of the address object
    const addressFields = [
      "country",
      "state",
      "city",
      "line_1",
      "line_2",
      "area",
      "zip",
    ];

    // Create the address object from formValues
    const address = addressFields.reduce((acc, field) => {
      if (formValues[field]) {
        acc[field] = formValues[field];
      }
      return acc;
    }, {});

    // Create a new object for form values excluding address fields
    const nonAddressFormValues = Object.keys(formValues)
      .filter((key) => !addressFields.includes(key))
      .reduce((obj, key) => {
        obj[key] = formValues[key];
        return obj;
      }, {});

    var payload = {
      ...nonAddressFormValues,
      category_name: PropertyCategoryStatus,
      property_type: changePropertyType(PropertyTypeStatus),
      category_id: categoryId,
      entity_type: "ENT_PROPERTY",
      address: address,
      // featured_image: featuredImage.image_id,
      images: { images: image_id },
      area_id: user.area_id,
      phone: formValues.phone || phoneNumber,
      amenities: {
        amenities: amenitiesList,
      },
      geo_location: {
        latitude: parseFloat(latitude).toFixed(5),
        longitude: parseFloat(longitude).toFixed(5),
      },
    };

    console.log("payload", payload);

    try {
      const PropertyResponse = await callApi(
        `${baseUrl}/modules/properties/create`,
        "POST",
        headers,
        payload
      );

      if (PropertyResponse.success) {
        console.log("success", PropertyResponse);
        Toast.fire({
          icon: "success",
          title: "Data saved successfully",
        });
        navigate("/properties");
      } else {
        setErrors(PropertyResponse.errors);
        console.error("Error: Response indicates failure", PropertyResponse);
        Toast.fire({
          icon: "error",
          title: PropertyResponse.message || "Failed to save data",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Toast.fire({
        icon: "error",
        title: "An error occurred while adding the Property",
      });
    }
  };

  const getUpdatedValues = () => {
    const updatedValues = {};
    for (const key in formValues) {
      if (formValues[key] !== originalFormValues[key]) {
        updatedValues[key] = formValues[key];
      }
    }
    return updatedValues;
  };

  // Prepare the geo_location object conditionally
  const geoLocation =
    latitude && longitude
      ? {
        latitude: parseFloat(latitude).toFixed(5),
        longitude: parseFloat(longitude).toFixed(5),
      }
      : propertyInfo?.geo_location; // Use existing values if not updated

  const handlePropertyUpdate = async (e) => {
    e.preventDefault();
    const updatedValues = getUpdatedValues();

    // Define which fields should be part of the address object
    const addressFields = [
      "country",
      "state",
      "city",
      "line_1",
      "line_2",
      "area",
      "zip",
    ];

    // Create the address object from updatedValues
    const address = addressFields.reduce((acc, field) => {
      if (updatedValues[field]) {
        acc[field] = updatedValues[field];
      }
      return acc;
    }, {});

    // Create a new object for updated values excluding address fields
    const nonAddressUpdatedValues = Object.keys(updatedValues)
      .filter((key) => !addressFields.includes(key))
      .reduce((obj, key) => {
        obj[key] = updatedValues[key];
        return obj;
      }, {});

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: user?.token,
    };
    var baseUrl = process.env.REACT_APP_DEV_BASE_URL;

    const payload = {
      property_id: id,
      ...nonAddressUpdatedValues,
      address,
      featured_image: featuredImage?.image_id,
      phone: formValues.phone || phoneNumber,
      images: {
        images:
          selectedImages.length > 0
            ? selectedImages.filter((img) => img).map((img) => img.image_id)
            : null,
      },
      // Only include amenities if the amenitiesList is not null or empty
      ...(amenitiesList &&
        amenitiesList.length > 0 && {
        amenities: {
          amenities: amenitiesList,
        },
      }),
      is_featured: isFeatured,
      action: "UPDATE",
      geo_location: geoLocation,
    };

    console.log("payload", payload);

    try {
      const propertyEditResponse = await callApi(
        `${baseUrl}/modules/properties/update`,
        "POST",
        headers,
        payload
      );
      if (propertyEditResponse.success) {
        console.log("success", propertyEditResponse);
        Toast.fire({
          icon: "success",
          title: "property edited successfully",
        });
        navigate("/properties");
      } else {
        console.error(
          "Error: Response indicates failure",
          propertyEditResponse
        );
        Toast.fire({
          icon: "error",
          title: propertyEditResponse.message || "Failed to edit property",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Toast.fire({
        icon: "error",
        title: "An error occurred while editing the property",
      });
    }
  };

  // handleStatus approve

  const handleStatusApprove = async () => {
    const baseUrl = process.env.REACT_APP_DEV_BASE_URL;
    const headers = {
      "Content-Type": "application/json",
      token: user?.token,
    };
    const payload = {
      property_id: id,
      status: "STS_LIVE",
      action: "APPROVE",
      phone: formValues.phone || phoneNumber,
    };

    try {
      const approveStatusResponse = await callApi(
        `${baseUrl}/modules/properties/update`,
        "POST",
        headers,
        payload
      );

      if (approveStatusResponse.success) {
        console.log("Status update success", approveStatusResponse);
        Toast.fire({
          title: "Approved",
          icon: "success",
        });
        navigate("/properties");
      } else {
        console.log("Status update failed", approveStatusResponse);
        Toast.fire({
          title: "Failed to Approve",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Toast.fire({
        title: "An error occurred while approving the property",
        icon: "error",
      });
    }
  };

  // handleStatus reject

  const handleRejectChange = (e) => {
    setRejectReason({ ...rejectReason, [e.target.name]: e.target.value });
  };

  const handleStatusReject = async () => {
    const baseUrl = process.env.REACT_APP_DEV_BASE_URL;
    const headers = {
      "Content-Type": "application/json",
      token: user?.token,
    };
    const payload = {
      property_id: id,
      reject_reason: rejectReason.reject_reason,
      phone: formValues.phone || phoneNumber,
      status: "STS_REJECTED",
      action: "REJECT",
    };
    console.log("payload status", payload);

    try {
      const rejectStatusResponse = await callApi(
        `${baseUrl}/modules/properties/update`,
        "POST",
        headers,
        payload
      );

      if (rejectStatusResponse.success) {
        console.log("Status update success", rejectStatusResponse);
        Toast.fire({
          title: "Rejected",
          icon: "success",
        });
        navigate("/properties");
      } else {
        console.log("Status update failed", rejectStatusResponse);
        Toast.fire({
          title: "Failed to Reject",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Toast.fire({
        title: "An error occurred while rejecting the property",
        icon: "error",
      });
    }
  };

  // handleStatus back to pending page

  const handleBackToPending = async () => {
    const baseUrl = process.env.REACT_APP_DEV_BASE_URL;
    const headers = {
      "Content-Type": "application/json",
      token: user?.token,
    };
    const payload = {
      property_id: id,
      status: "STS_PENDING",
      action: "APPROVE",
      phone: formValues.phone || phoneNumber,
    };
    console.log("your payload", payload);
    try {
      const backToPendingResponse = await callApi(
        `${baseUrl}/modules/properties/update`,
        "POST",
        headers,
        payload
      );

      if (backToPendingResponse.success) {
        console.log("backToPendingResponse", backToPendingResponse);
        Toast.fire({
          title: "Move to pending page",
          icon: "success",
        });
        navigate("/properties");
      } else {
        Toast.fire({
          title: "Failed to move pending page",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <div className="page-heading">
        <div className="page-title">
          {!isEditMode && (
            <div className="row">
              <div className="col-md-3">
                <fieldset className="form-group">
                  <select
                    id="property-category"
                    className="form-select"
                    value={PropertyCategoryStatus}
                    onChange={(e) => {
                      const selectedCategory = categoryData.find(
                        (category) => category.value === e.target.value
                      );
                      setPropertyCategoryStatus(e.target.value);
                      setCategoryId(selectedCategory.id || 263);
                    }}
                  >
                    {categoryData.map((category) => (
                      <option key={category.id} value={category.value}>
                        {category.label}
                      </option>
                    ))}
                  </select>
                </fieldset>
              </div>

              {["Buy", "Rent"].includes(PropertyCategoryStatus) && (
                <div className="col-md-3">
                  <fieldset className="form-group">
                    <select
                      id="property-type"
                      className="form-select"
                      value={PropertyTypeStatus}
                      onChange={(e) => setPropertyTypeStatus(e.target.value)}
                    >
                      <option value="PROPERTY_TYPE_HOUSE">
                        Individual House
                      </option>
                      <option value="PROPERTY_TYPE_VILLA">Villa</option>
                      <option value="PROPERTY_TYPE_APARTMENT">Apartment</option>
                      <option value="PROPERTY_TYPE_LAND">Land</option>
                      <option value="PROPERTY_TYPE_SHOP">Shop</option>
                    </select>
                  </fieldset>
                </div>
              )}
            </div>
          )}
          {isEditMode && (
            <div className="row d-flex align-items-center justify-content-between mb-3">
              <div className="col-md-6">
                <h3>Edit Property</h3>
              </div>
              <div className="col-md-6 d-flex justify-content-end">
                {status === "STS_LIVE" ? (
                  <div>
                    <div class="form-check">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="form-check-input form-check-danger"
                          name="customCheck"
                          id="customColorCheck1"
                          onChange={handleCheckboxChange}
                          checked={isFeatured}
                        ></input>
                        <label class="form-check-label" for="customColorCheck1">
                          Is Featured
                        </label>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div>
                  {status === "STS_PENDING" ? (
                    <>
                      <button
                        className="btn btn-success me-1 mb-1"
                        onClick={() => handleStatusApprove()}
                      >
                        Approve
                      </button>

                      <button
                        type="button"
                        className="btn btn-danger me-1 mb-1"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                      >
                        Reject
                      </button>

                      {/* <!-- Modal for Reject Button --> */}
                      <div
                        className="modal fade"
                        id="staticBackdrop"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        aria-labelledby="staticBackdropLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="staticBackdropLabel"
                              >
                                Reason for Rejection
                              </h5>
                            </div>
                            <div className="modal-body">
                              <textarea
                                className="form-control"
                                id="reject_reason"
                                rows="3"
                                name="reject_reason"
                                value={rejectReason.reject_reason || ""}
                                onChange={handleRejectChange}
                                required
                              />
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary"
                                data-bs-dismiss="modal"
                                onClick={() => handleStatusReject()}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}

                  {/* <!--Button to move rejected services to the pending page --> */}

                  {status === "STS_REJECTED" && (
                    <>
                      <button
                        className="btn btn-success me-1 mb-1"
                        onClick={() => handleBackToPending()}
                      >
                        Move to pending
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <section className="section">
        <section id="basic-horizontal-layouts">
          <div className="row match-height">
            <div className="col-md-10 col-lg-10">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">
                    {propertyTypeLabelToshow(PropertyTypeStatus)}
                  </h4>
                </div>
                <div className="card-content">
                  <div className="card-body">
                    <form
                      className="form form-horizontal"
                      onSubmit={
                        isEditMode ? handlePropertyUpdate : handlePropertySave
                      }
                    >
                      <div className="form-body">
                        {getAllFieldConfigForPropertyType()}
                      </div>

                      <div className="mt-3">{renderButton()}</div>

                      {/* for phoneNumberVerify */}
                      <MobileNumberVerified
                        userInfo={userInfo}
                        setUserInfo={setUserInfo}
                        isEditMode={isEditMode}
                        phoneVerifyModal={phoneVerifyModal}
                        setPhoneVerifyModal={setPhoneVerifyModal}
                        phoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                        handleMobileNumberVerify={handleMobileNumberVerify}
                        newPhoneNumber={newPhoneNumber}
                        setNewPhoneNumber={setNewPhoneNumber}
                        setFormValues={setFormValues}
                        setIsEditMode={setIsEditMode}
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}

export default PropertyForm;
