export const menuItemsData = [
  { title: "Dashboard", url: "/dashboard"},
  {
    title: "Shops",
    subMenu: [
      { title: "All", url: "/shops" },
      { title: "Add New", url: "/shop-new" },
    ],
  },
  {
    title: "Services",
    subMenu: [
      { title: "All", url: "/services" },
      { title: "Add New", url: "/service-new" },
    ],
  },
  {
    title: "Real Estate",
    subMenu: [
      { title: "All", url: "/properties" },
      { title: "Add New", url: "/property-new" },
    ],
  },
  {
    title: "Banner",
    subMenu: [
      { title: "All", url: "/banner-list" },
      { title: "Add New", url: "/banner-new" }
    ],
  },

  {
    title: "Users",
    subMenu: [
      { title: "All", url: "/users" },
      { title: "Add New", url: "/user-new" },
    ],
  },


  // {
  //   title: "Settings",
  //   url: "/settings",
  //   subMenu: [
  //     { title: "Info", url: "/info" },
      
  //   ],
  // },
];
